<template>
  <div>
    <div v-if="this.$route.name === 'SupplierDebtsReport'">
      <SupplierDebtsReport />
    </div>
    <router-view :key="$route.fullPath" />
  </div>
</template>
<script>
import SupplierDebtsReport from "@/views/companyApp/supplierDebtsReport/SupplierDebtsReport.vue";

export default {
  name: "SupplierDebtsReportParent",
  components: {
    SupplierDebtsReport,
  },
};
</script>
